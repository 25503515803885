import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
function AiAgent () {
    const [bots, setBots] = useState([]); 
    const [botName, setBotName] = useState(""); 
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); 
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
    const fetchBot = async () => {
        try {
          setLoading(true);
            const response = await fetch("https://milio-backend-production.up.railway.app/bots",  {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
           
            const data = await response.json();
            if (data && data.data && data.data.length > 0) {
                setBots(data.data);
          } else {
                setBots([]);
          }
        } catch (error) {
            console.error("Error fetching Bots:", error);
            alert("Error", error);
            setBots([]);
        }
        setLoading(false);
    };
    const createBot = async (botname, description) => {
      try {
        setLoading(true);
          const response = await fetch("https://milio-backend-production.up.railway.app/bots",  {
              method: "POST",
              headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: botname,
                description: description,
            }),
          });
         
          const data = await response.json();
          if( data.success == true) {
            navigate(`/bot/settings/${data.data[0].id}`);
          }
      } catch (error) {
          alert("Error", error);
          console.error("Error creating Bots:", error);
      }
      setLoading(false);
  };
  const deleteBot = async (id) => {
    try {
       
        setLoading(true);

        const response = await fetch(`https://milio-backend-production.up.railway.app/bots/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const responseBody = await response.text();

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${responseBody}`);
        }
        navigate(`/ai-agent`);

    } catch (error) {
        console.error("Error deleting bot:", error);
    } finally {
        setLoading(false);
    }
};

      useEffect(() => {
        fetchBot();
    }, []);
    if (loading) {
      return  <ClipLoader size={50} color={"#f1c40f"} />; 
  }
    return (
        <div className="flex flex-col text-gray-800">
        {/* Main Content */}
        <main className="flex-1 flex flex-col lg:flex-row">
          {/* Left Side */}
          <div className="flex-1 flex flex-col items-center justify-center p-4 md:p-6">
            {/* Create Chatbot Card */}
            <div className="border-2 border-dashed border-gray-300 bg-white shadow-md w-full max-w-lg h-auto flex flex-col items-center justify-center rounded-lg p-4">
              <h3 className="text-lg font-bold mb-4">Create a Chatbot</h3>
              <input
                type="text"
                className="border w-full px-4 py-2 rounded-lg mb-4"
                placeholder="Enter Bot Name"
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
               <input
                type="text"
                className="border w-full px-4 py-2 rounded-lg mb-4"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
             
              <button
              onClick={() =>
               createBot(botName, description)
              }
                className="bg-yellow-300 px-6 py-2 rounded-md hover:bg-yellow-400"
              >
                Create Bot
              </button>
            </div>
  
            {/* Bots List */}
            <div className="w-full max-w-lg mt-8">
              <h3 className="text-lg font-bold mb-4">My Bots</h3>
              <ul className="space-y-4">
                {bots.length > 0 ? (
                   bots.map((bot) => (
                    <li
                      key={bot.id}
                      className="flex justify-between items-center bg-white p-4 shadow-md rounded-lg"
                    >
                      <div>
                        <p className="font-bold">{bot.name}</p>
                        <p className="text-sm text-gray-500">Bot ID: {bot.id}</p>
                      </div>
                      <div className="flex space-x-2">
                        
                        <button
                          onClick={() =>
                            navigate(`/bot/settings/${bot.id}`)
                          }
                          className="text-blue-600 hover:underline"
                        >
                          Edit
                        </button>
                       
                        <button
                          onClick={() => deleteBot(bot.id)}
                          className="text-red-600 hover:underline"
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="text-gray-500">No bots found. Create one to get started!</p>
              )}
              </ul>
            </div>
          </div>
  
        
          <aside className="w-full lg:w-80 bg-white p-4 md:p-6 shadow-md">
            <h2 className="text-lg font-semibold text-gray-800">
              Getting Started
            </h2>
            <ul className="mt-4 text-sm text-gray-600 space-y-4">
              <li>
                <span className="font-medium">Create a bot</span>
                <span className="text-blue-600 float-right">{bots.length}</span>
              </li>
              <li>
                <span className="font-medium">Add Data/Integrations</span>
                <span className="text-blue-600 float-right">5</span>
              </li>
              <li>
                <span className="font-medium">Customize</span>
                <span className="text-blue-600 float-right">5</span>
              </li>
              <li>
                <span className="font-medium">Test/Chat</span>
                <span className="text-blue-600 float-right">5</span>
              </li>
              <li>
                <span className="font-medium">Publish</span>
                <span className="text-blue-600 float-right">10</span>
              </li>
            </ul>
            <div className="mt-6 text-sm">
              <p>
                Credits Used: <span className="font-bold">0/35</span>
              </p>
              <button className="bg-yellow-300 w-full mt-4 py-2 rounded-md">
                Subscription
              </button>
            </div>
          </aside>
        </main>
      </div>
    );
};

export default AiAgent;