
function Settings() {
    return (
        <div>
        <h1> Ini Settings</h1>
      </div>
    );
}

export default Settings;
