import { Navigate, Outlet } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const ProtectedRoute = () => {
  const token = localStorage.getItem("authToken");

  const isTokenValid = (token) => {
      if (!token) return false;

      try {
          const { exp } = jwtDecode(token); 
          const currentTime = Date.now() / 1000; 

          return exp > currentTime; 
      } catch (error) {
          console.error("Invalid token:", error);
          return false;
      }
  };

  return isTokenValid(token) ? <Outlet /> : <Navigate to="/login" />;
};
  
  export default ProtectedRoute;