import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {   toast, ToastContainer } from "react-toastify";

const MyAccount = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  
  const updatePassword = async (oldpass, newpass) => {
    try {
        setLoading(true);
        const response = await fetch("https://milio-backend-production.up.railway.app/auth/change-password",  {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                oldPassword: oldpass,
                newPassword: newpass,
            }),
        });
       
        const data = await response.json();
        
       if (response.ok) {
        alert("Update Password Berhasil", response.body);
        navigate("/login");
       }
    } catch (error) {
      alert("Error", error);
        console.error("Error fetching plans:", error);
    }
    setLoading(false);
};

  useEffect(() => {
    if (user) {
        const userObject = JSON.parse(user);
        setEmail(userObject.email);
        setPhone(userObject.phone);
      } else {
        console.log("No user found in localStorage");
      }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <ToastContainer></ToastContainer>
      <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">My Account</h2>
        <form>
          {/* Email */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">Email</label>
            <p className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600">{email}</p>
          </div>

          {/* Phone */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">Phone Number</label>
            <p className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600">{phone ? phone : 0}</p>
          </div>

          {/* Current Password */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">Current Password</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Enter current password"
              required
            />
          </div>

          {/* New Password */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Enter new password"
              required
            />
          </div>

          <div className="flex justify-center">
          <button
  onClick={() =>
    updatePassword(currentPassword, newPassword)
   }
  type="submit"
  className="w-full bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 flex justify-center items-center"
>
  {loading ? (
    <svg
      className="animate-spin h-5 w-5 text-slate-950"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ) : (
    "Change Password"
  )}
</button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default MyAccount;
