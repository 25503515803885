
import './App.css';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Login from './pages/LoginRegister/Login';
import LoginRegister from './pages/LoginRegister/LoginRegister';
import Dashboard from './pages/Dashboard/Dashboard'
import Register from './pages/LoginRegister/Register';
import Layout from './layout/Layout';
import Settings from './pages/Settings/Settings';
import Billings from './pages/Billings/Billings';
import Chatbot from './pages/Chatbot/Chatbot';
import PrivacyPolicy from './components/Privacy-Policy';
import LandingPage from './pages/LandingPage/LandingPage';
import InboxChat from './pages/ChatPage/ChatPage';
import AiAgent from './pages/Chatbot/Ai-agent';
import TrainedBot from './pages/Chatbot/TrainedFile';
import { AppProvider } from './context/AppContext';
import MyAccount from './pages/Account/MyAccount';
import Contacts from './pages/Contacts/Contacts';
import BroadcastSettings from './pages/Broadcast/Broadcast';
import HumanAgent from './pages/HumanAgent/HumanAgent';
import ConnectedPlatforms from './pages/ConnectedPlatform/ConnectedPlatform';


const AppRoutes = () => {
  const routes = useRoutes([
    {
      element: <LoginRegister />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/register", element: <Register /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
      ]
    },
    {
      path: "",
      element: <Layout/>,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/settings", element: <Settings /> },
        { path: "/billings", element: <Billings /> },
        { path: "/ai-agent", element: <AiAgent /> },
        { path: "/human-agent", element: <HumanAgent /> },
        { path: "/contacts", element: <Contacts /> },
        { path: "/broadcast", element: <BroadcastSettings /> },
        { path: "/bot/settings/:id", element: <TrainedBot /> },
        { path: "/inbox", element: <InboxChat /> },
        { path: "/connected-platform", element: <ConnectedPlatforms /> },
        { path: "/myaccount", element: <MyAccount /> },
        { path: "/chatbot", element: <Chatbot /> },
        
      ],
    },
  ]);

  return routes;
};
function App() {
  return (
    <AppProvider>
      <Router>
      <AppRoutes />
    </Router>
    </AppProvider>
    
 
  );
}

export default App;
