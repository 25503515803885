
function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8 text-gray-700">
        <div className="flex items-center justify-center mb-6">
          <img
            src="logo_milio.jpeg" 
            alt="Milio Logo"
            className="h-20" 
          />
        </div>
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Privacy Policy</h1>
        <p className="text-sm text-gray-500">Last Updated: November 24, 2024</p>
        <p className="text-gray-600">
          Welcome to MilioBot AI from PT AERO NUSANTARA TEKNOLOGI on miliobot.com! Your privacy is
          important to us. This Privacy Policy explains how we collect, use, disclose, and protect
          your personal data. By using MilioBot AI, you agree to the collection and use of
          information in accordance with this policy.
        </p>
      </div>

      {/* Sections */}
      <div className="space-y-8">
        {/* Section 1: Introduction */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">1. Introduction</h2>
          <p>
            MilioBot AI provides chatbot solutions for businesses to use as customer service tools.
            This policy outlines how MilioBot AI processes data from businesses (our clients) and
            their customers. We ensure compliance with all applicable privacy laws and data
            protection regulations. If you have any questions, please contact us at{" "}
            <a
              href="mailto:miliochatbotai@gmail.com"
              className="text-blue-600 hover:underline"
            >
              miliochatbotai@gmail.com
            </a>
            .
          </p>
        </div>

        {/* Section 2: Definitions */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Definitions</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              <strong>Client:</strong> A business entity using MilioBot AI services to communicate
              with their customers.
            </li>
            <li>
              <strong>Customer:</strong> An individual interacting with the chatbot or agent of our
              Client.
            </li>
            <li>
              <strong>Personal Data:</strong> Any information relating to an identifiable
              individual.
            </li>
            <li>
              <strong>Platform Data:</strong> Data collected through Meta platforms such as WhatsApp
              Business API.
            </li>
          </ul>
        </div>

        {/* Section 3: Data We Collect */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">3. Data We Collect</h2>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">From Our Clients</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Business Name, Logo, and Address</li>
            <li>Email Address</li>
            <li>Subscription Plan Details</li>
            <li>Metadata on Bot Settings (e.g., responses, goals, configurations)</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-700 mt-4 mb-2">
            From Customers (on behalf of Clients)
          </h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Customer phone number (via WhatsApp or other channels)</li>
            <li>Message content and conversation history</li>
            <li>Metadata such as timestamps and delivery/read receipts</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-700 mt-4 mb-2">
            Automatically Collected Data
          </h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Cookies: To enhance user experience and track analytics</li>
            <li>
              Device Information: Includes IP address, browser type, operating system, and usage
              statistics
            </li>
            <li>
              Platform Data: Data from WhatsApp Business API such as delivery statuses and media
              files shared during conversations
            </li>
          </ul>
        </div>

        {/* Section 4: How We Use Data */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">4. How We Use Data</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Provide and improve chatbot services and human handover for Clients</li>
            <li>Facilitate communication between Clients and their customers</li>
            <li>Ensure compliance with WhatsApp and Meta guidelines</li>
            <li>Analyze usage patterns for better user experience</li>
            <li>Manage Client subscriptions, payments, and billing</li>
          </ul>
        </div>

        {/* Section 5: Sharing and Disclosure of Data */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Sharing and Disclosure of Data</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Share customer messages and metadata with respective Clients</li>
            <li>
              Share data with Meta platforms (e.g., WhatsApp Business API) and payment gateways
              (e.g., Midtrans)
            </li>
            <li>Disclose data when required by law or to protect rights and property</li>
          </ul>
        </div>
     
      
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Data Retention</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              <strong>Chat History:</strong> Stored for up to 24 months or as configured by the Client.
            </li>
            <li>
              <strong>Client Data:</strong> Retained until the Client terminates their subscription.
            </li>
            <li>
              <strong>Analytics Data:</strong> Stored in anonymized form for future improvements.
            </li>
          </ul>
        </div>

        {/* Section 7: Data Security */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">7. Data Security</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              <strong>Encryption:</strong> All data in transit and at rest is encrypted using industry-standard protocols.
            </li>
            <li>
              <strong>Access Control:</strong> Restricted access to authorized personnel only.
            </li>
            <li>
              <strong>Regular Audits:</strong> Conducted to ensure compliance with data protection policies.
            </li>
          </ul>
          <p className="text-sm mt-4">
            However, no method of transmission over the Internet is 100% secure, and we cannot guarantee absolute security.
          </p>
        </div>

        {/* Section 8: User Rights */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">8. User Rights</h2>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">For Clients</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Access and download their business data from their dashboard.</li>
            <li>Request the deletion of account and associated data at any time.</li>
            <li>Update chatbot configurations and bot data.</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-700 mt-4 mb-2">For Customers</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Request a copy of their chat history.</li>
            <li>Request that their data be deleted from the Client's system.</li>
          </ul>
          <p className="text-sm mt-4">
            To exercise these rights, contact us at{" "}
            <a href="mailto:miliochatbotai@gmail.com" className="text-blue-600 hover:underline">
              miliochatbotai@gmail.com
            </a>
            .
          </p>
        </div>

        {/* Section 9: Cookies Policy */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">9. Cookies Policy</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Enhance user experience.</li>
            <li>Track traffic and usage patterns.</li>
          </ul>
          <p className="text-sm mt-4">
            You can control or disable cookies via your browser settings. Note that disabling cookies may affect some functionality.
          </p>
        </div>

        {/* Section 10: Children's Privacy */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">10. Children's Privacy</h2>
          <p>
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from children under 13. If you believe that a child has provided us with personal data, contact us
            immediately.
          </p>
        </div>

        {/* Section 11: Third-Party Services */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">11. Third-Party Services</h2>
          <p>
            Our platform may contain links to third-party websites or services. We are not responsible for the privacy policies
            or practices of these third parties. We encourage you to review their policies.
          </p>
        </div>

        {/* Section 12: Compliance with Meta Platform Terms */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">12. Compliance with Meta Platform Terms</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              <strong>Data Use:</strong> Data collected through WhatsApp Business is used only to facilitate Client-customer
              interactions.
            </li>
            <li>
              <strong>No Unauthorized Use:</strong> Data from Meta is not shared or sold to any third parties.
            </li>
            <li>
              <strong>Secure Communication:</strong> All data exchange with Meta is encrypted and secure.
            </li>
          </ul>
        </div>

        {/* Section 13: Changes to Privacy Policy */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">13. Changes to Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Changes will be notified via email or posted on our website. It
            is your responsibility to review this policy periodically.
          </p>
        </div>

        {/* Section 14: Contact Us */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">14. Contact Us</h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Email:{" "}
              <a
                href="mailto:miliochatbotai@gmail.com"
                className="text-blue-600 hover:underline"
              >
                miliochatbotai@gmail.com
              </a>
            </li>
            <li>WhatsApp: 08225036811</li>
            <li>Address: PT AERO NUSANTARA TEKNOLOGI, Gowa, Sulawesi Selatan, Indonesia</li>
          </ul>
        </div>
      </div>
     </div>
  );
}

export default PrivacyPolicy;
