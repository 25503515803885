import React, { useState, useEffect } from "react";
import Template from "./Template";
import Receipent from "./Receipent";
import Campaign from "./Campaign";

const BroadcastSettings = () => {
  const [templates, setTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    template_name: "",
    template_type: "",
    platform_name: "",
    language: "",
    category: "",
  });
  const [activeTab, setActiveTab] = useState("templateMessage");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const rowsPerPage = 10;

  useEffect(() => {
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab); 
  };

  const handleAddTemplate = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewTemplate({
      template_name: "",
      template_type: "",
      platform_name: "",
      language: "",
      category: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveTemplate = async () => {
    if (
      newTemplate.template_name &&
      newTemplate.template_type &&
      newTemplate.platform_name &&
      newTemplate.language &&
      newTemplate.category
    ) {
      setLoading(true);
      try {
        const response = await fetch(
          "https://milio-backend-production.up.railway.app/broadcast/templates",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newTemplate),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTemplates((prev) => [...prev, data]);
          alert("Template added successfully!");
          handleCloseModal();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message || "Failed to add template"}`);
        }
      } catch (error) {
        alert(`Error: ${error.message || "Network error"}`);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const paginatedTemplates = templates.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">
        Broadcast Settings
      </h1>
      {/* Tab Navigation */}
      <div className="flex mb-6 border-b">
        <button
          className={`px-4 py-2 ${
            activeTab === "recipient" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("recipient")}
        >
          Recipient
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "templateMessage" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("templateMessage")}
        >
          Template Message
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "campaign" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("campaign")}
        >
          Campaign
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "followUp" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("followUp")}
        >
          Follow Up
        </button>
      </div>

      
      {activeTab === "recipient" && (
         <Receipent/>
      )}
      {activeTab === "templateMessage" && (
         <Template/>
        
      )}
      {activeTab === "campaign" && (
        <Campaign/>
      )}
      {activeTab === "followUp" && (
        <div>
          <h2 className="text-lg font-bold mb-4">Follow Up Settings</h2>
          <p>Manage follow-ups here.</p>
        </div>
      )}
       <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
        <div className="text-sm sm:text-base">
          Page {currentPage} of {Math.ceil(templates.length / rowsPerPage) || 1}
        </div>
        <div className="flex space-x-2 mt-2 sm:mt-0">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50 text-sm sm:text-base"
          >
            Previous
          </button>
          <button
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(templates.length / rowsPerPage))
              )
            }
            disabled={
              currentPage === Math.ceil(templates.length / rowsPerPage) || 1
            }
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50 text-sm sm:text-base"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BroadcastSettings;
