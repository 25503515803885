import { Outlet } from "react-router-dom";
import {  ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const LoginRegister = () => {
    return (
      <div>
        <ToastContainer></ToastContainer>
        <main>
          <Outlet /> 
        </main>
      </div>
    );
  };

  export default LoginRegister;