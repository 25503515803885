import React, { useEffect, useState } from "react";
import axios from "axios";

const ReceipentsDropdown = ({ onReceipentChange }) => {
  const [receipents, setReceipents] = useState([]); // State untuk menyimpan kontak
  const [selectedReceipents, setSelectedReceipents] = useState(""); // State untuk kontak yang dipilih
  const apiUrl = "https://milio-backend-production.up.railway.app/broadcast/recipients";
  const token = localStorage.getItem("authToken");
 
  useEffect(() => {
    const fetchReceipents = async () => {
      try {
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          });
        setReceipents(response.data.data); 
      } catch (error) {
        console.error("Error fetching receipents:", error);
      }
    };

    fetchReceipents();
  }, []);

 
  const handleSelectChange = (e) => {
    setSelectedReceipents(e.target.value);
    onReceipentChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor="contacts-dropdown">Pilih Receipents:</label>
      <select
        id="contacts-dropdown"
        value={selectedReceipents}
        onChange={handleSelectChange}
      >
        <option value="">-- Pilih Receipents --</option>
        {receipents.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.name} 
          </option>
        ))}
      </select>
      {selectedReceipents && (
        <p>
          Receipents dipilih:{" "}
          {receipents.find((contact) => contact.id === selectedReceipents)?.name}
        </p>
      )}
    </div>
  );
};

export default ReceipentsDropdown;
