import Footer from "../../components/Footer";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function Dashboard() {
  const { bot } = useContext(AppContext);

  return (
    <div>
      {/* Overview Cards */}
      <section
        id="overview"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6"
      >
        <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">0</h2>
          <p className="text-gray-600">Active Bots</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">0</h2>
          <p className="text-gray-600">Conversations Today</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">100%</h2>
          <p className="text-gray-600">Satisfaction Rate</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">0</h2>
          <p className="text-gray-600">Total Conversations</p>
        </div>
      </section>

      {/* My Bots Section */}
      <section id="bots" className="mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">My Bots</h2>
        {bot && bot.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {bot.map((botData, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-lg shadow"
              >
                <h3 className="text-lg font-bold text-gray-800">
                  {botData.name || "Untitled Bot"}
                </h3>
                <p className="text-gray-600 mb-4">
                  {botData.description || "No description available."}
                </p>
                <button className="text-yellow-400 hover:underline">
                  Manage
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600">You have no bots. Create one to get started!</p>
        )}
      </section>

      {/* Analytics Section */}
      <section id="analytics" className="mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Analytics</h2>
        <div className="bg-white p-6 rounded-lg shadow">
          <p className="text-gray-600 mb-4">
            Analytics data visualization will go here (charts, graphs, etc.).
          </p>
          <button className="bg-yellow-400 text-white px-4 py-2 rounded shadow hover:bg-blue-700">
            View Detailed Analytics
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Dashboard;
