import React, { useState, useEffect } from "react";

const HumanAgent = () => {
  const [agents, setAgents] = useState([]); // Daftar agen
  const [search, setSearch] = useState(""); // Pencarian agen
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal untuk tambah agen
  const [newAgent, setNewAgent] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");

  // Fungsi untuk fetch data agen
  const fetchAgents = async () => {
    try {
      const response = await fetch(
        "https://milio-backend-production.up.railway.app/agent",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAgents(data.agents); // Update state dengan data agen
      } else {
        alert("Failed to fetch agents");
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
      alert("Error fetching agents");
    }
  };

  
  useEffect(() => {
    fetchAgents(); 
  }, []);

  // Fungsi pencarian agen
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // Fungsi membuka modal untuk tambah agen
  const handleAddAgent = () => {
    setIsModalOpen(true);
  };

  // Fungsi menutup modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewAgent({
      name: "",
      email: "",
      password: "",
      role: "",
    });
  };

  // Fungsi input perubahan form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAgent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Fungsi untuk menambah agen baru
  const handleSaveAgent = async () => {
    if (newAgent.name && newAgent.email && newAgent.password && newAgent.role) {
      setLoading(true);
      try {
        const response = await fetch(
          "https://milio-backend-production.up.railway.app/agent",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newAgent),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAgents((prev) => [...prev, data.data]); // Update state dengan agen baru
          alert("Agent added successfully!");
          handleCloseModal();
        } else {
          alert("Failed to add agent");
        }
      } catch (error) {
        console.error("Error adding agent:", error);
        alert("Error adding agent");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  // Filter agen berdasarkan pencarian
  const filteredAgents = agents.filter(
    (agent) =>
      agent.user_id.toLowerCase().includes(search.toLowerCase()) ||
      agent.role_id.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="p-6  min-h-screen">
      {/* Header */}
      <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">
        Human Agents
      </h1>

      {/* Pencarian */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Name or Email"
          value={search}
          onChange={handleSearch}
          className="w-full sm:w-1/3 border px-4 py-2 rounded"
        />
      </div>

      {/* Tabel agen */}
      <table className="min-w-full table-auto bg-white rounded-lg shadow-md">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 text-left">User Id</th>
            <th className="px-4 py-2 text-left">Email</th>
            <th className="px-4 py-2 text-left">Role</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAgents.map((agent) => (
            <tr key={agent.user_id} className="border-b">
              <td className="px-4 py-2">{agent.user_id}</td>
              <td className="px-4 py-2">{agent.email}</td>
              <td className="px-4 py-2">{agent.role_id}</td>
              <td className="px-4 py-2">
                {/* Tombol aksi Edit dan Hapus */}
                <button
                  onClick={() => alert("Edit agent clicked")}
                  className="text-blue-500 hover:text-blue-700 px-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => alert("Delete agent clicked")}
                  className="text-red-500 hover:text-red-700 px-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Tombol untuk menambah agen */}
      <div className="flex justify-center mt-6">
        <button
          onClick={handleAddAgent}
          className="px-6 py-3 bg-yellow-500 text-white rounded-lg"
        >
          Add Agent
        </button>
      </div>

      {/* Modal untuk tambah agen */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <h2 className="text-xl font-bold mb-4">Add Agent</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={newAgent.name}
                onChange={handleInputChange}
                placeholder="Name"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="email"
                name="email"
                value={newAgent.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="password"
                name="password"
                value={newAgent.password}
                onChange={handleInputChange}
                placeholder="Password"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="role"
                value={newAgent.role}
                onChange={handleInputChange}
                placeholder="Role"
                className="w-full border px-4 py-2 rounded"
              />
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveAgent}
                className={`px-4 py-2 ${
                  loading ? "bg-yellow-500" : "bg-yellow-500"
                } text-white rounded`}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HumanAgent;
