
import PlanCard from "./PlanCard";

const PricingPlans = ({plans}) => {
    return (
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {plans.prices.map((price) => (
                        <PlanCard price={price.price} duration={price.duration} plan_id={price.plan_id}/>
                    ))}
        </div>
    );
};

export default PricingPlans;
