import React, { useState,  useContext, useEffect } from "react";
import { AppContext } from '../../context/AppContext';
import PricingPlans from "../../components/PricingPlan";
import UserPlan from "../../components/UserPlan";

function Billings() {
    const [showModal, setShowModal] = useState(false);
    const { userPlans, plans  } = useContext(AppContext);
    const [businessPlans, setBusinessPlans] = useState([]);
    const [enterprisePlans, setEnterprisePlans] = useState([]);
    const [UnlimitedPlans, setUnlimitedPlans] = useState([]);
    const [ProPlans, setProPlans] = useState([]);
    const [activePlans, setActivePlans] = useState(null); // Default null untuk mengecek apakah ada plan yang aktif
  const [loading, setLoading] = useState(true); // Tambahkan state untuk loading
  const [error, setError] = useState(null);
   
  useEffect(() => {
    if (plans && plans.length > 0) {
      setBusinessPlans(plans.filter((plan) => plan.name === "Business"));
      setEnterprisePlans(plans.filter((plan) => plan.name === "Enterprise"));
      setUnlimitedPlans(plans.filter((plan) => plan.name === "Unlimited"));
      setProPlans(plans.filter((plan) => plan.name === "Pro"));
      setActivePlans(plans.find((plan) => plan.name === "Business") || null);
    } else {
      setError("No plans available."); // Set error jika data tidak ada
    }
    setLoading(false);
  }, [plans]);
  return (
    <div className="container mx-auto px-4 py-3">
      {loading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <>
          <UserPlan activeplans={userPlans && userPlans[0]} />
          <div className="flex justify-center px-4 py-2 w-auto bg-yellow-300 space-x-4 mb-8 shadow-lg rounded-lg">
            {businessPlans.length > 0 && (
              <button
                onClick={() => setActivePlans(businessPlans[0])}
                className="px-6 py-2 text-sm font-medium text-white bg-yellow-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-200"
              >
                {businessPlans[0].name}
              </button>
            )}
            {enterprisePlans.length > 0 && (
              <button
                onClick={() => setActivePlans(enterprisePlans[0])}
                className="px-6 py-2 text-sm font-medium text-white bg-yellow-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-200"
              >
                {enterprisePlans[0].name}
              </button>
            )}
            {UnlimitedPlans.length > 0 && (
              <button
                onClick={() => setActivePlans(UnlimitedPlans[0])}
                className="px-6 py-2 text-sm font-medium text-white bg-yellow-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-200"
              >
                {UnlimitedPlans[0].name}
              </button>
            )}
            {ProPlans.length > 0 && (
              <button
                onClick={() => setActivePlans(ProPlans[0])}
                className="px-6 py-2 text-sm font-medium text-white bg-yellow-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-200"
              >
                {ProPlans[0].name}
              </button>
            )}
          </div>

          {activePlans ? (
            <>
              <h1 className="text-2xl font-bold mb-6">{activePlans.description}</h1>
              <PricingPlans plans={activePlans} />
            </>
          ) : (
            <p className="text-center text-gray-600">
              No active plan selected. Please choose a plan.
            </p>
          )}
        </>
      )}

      <button
        onClick={() => setShowModal(true)}
        className="fixed bottom-4 right-4 bg-yellow-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:bg-yellow-600 transition"
      >
        View Transactions
      </button>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white w-full max-w-3xl mx-4 md:mx-auto rounded-lg shadow-lg overflow-hidden">
            <div className="bg-yellow-500 text-white py-4 px-6 flex justify-between items-center">
              <h2 className="text-lg md:text-2xl font-semibold">Transaction History</h2>
              <button
                onClick={() => setShowModal(false)}
                className="text-white text-xl font-bold hover:text-gray-200"
              >
                ✖
              </button>
            </div>

            <div className="p-6 space-y-4 max-h-[70vh] overflow-y-auto">
              {userPlans && userPlans.length > 0 ? (
                <ul className="space-y-4">
                  {userPlans.map((transaction, index) => (
                    <li
                      key={index}
                      className="bg-gray-100 rounded-lg p-4 shadow-sm border border-gray-200"
                    >
                      <p className="text-gray-800">
                        <strong>Plan:</strong> {transaction.plans.name}
                      </p>
                      <p className="text-gray-800">
                        <strong>Duration:</strong> {transaction.duration}
                      </p>
                      <p className="text-gray-800">
                        <strong>Price:</strong> IDR {Number(transaction.price).toLocaleString()}
                      </p>
                      <p className="text-gray-800">
                        <strong>Status:</strong> {transaction.status}
                      </p>
                      <p className="text-gray-800">
                        <strong>Start Date:</strong>{" "}
                        {new Date(transaction.start_date).toLocaleString()}
                      </p>
                      <p className="text-gray-800">
                        <strong>End Date:</strong>{" "}
                        {new Date(transaction.end_date).toLocaleString()}
                      </p>

                      {transaction.status === "pending" && (
                        <div className="flex space-x-4 mt-4">
                          <button className="bg-green-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-green-600 transition">
                            Pay
                          </button>
                          <button className="bg-red-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-red-600 transition">
                            Cancel
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">No transaction history available.</p>
              )}
            </div>

            <div className="bg-gray-100 py-3 px-6 flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-yellow-600 transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  }

export default Billings;
