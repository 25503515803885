import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
function TrainedBot()  {
    const { id } = useParams();
    const [botName, setBotName] = useState(""); 
    const [description, setDescription] = useState("");
    const token = localStorage.getItem("authToken");
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };
    const [loadingtrained, setLoadingtrained] = useState(false);
    const navigate = useNavigate();
    const editBot = async (id, name, description) => {
        try {
            setLoading(true);
            await fetch(`https://milio-backend-production.up.railway.app/bots/${id}`,  {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: name,
                  description: description,
              }),
            });
            navigate("/ai-agent");
        } catch (error) {
            console.error("Error Edit Bots:", error);
        }
        setLoading(false);
      };
    const trainingfile = async (file, botid) => {
        const formData = new FormData();
        formData.append('file', file); 
        formData.append('bot_id', botid); 
        try {
            setLoadingtrained(true);
            const response = await fetch("https://milio-backend-production.up.railway.app/training/file",  {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            const data = await response.json();
            if(data.success == true) {
                navigate("/ai-agent");
            }
        } catch (error) {
            console.error("Error creating file:", error);
        }
        setLoadingtrained(false);
    };
    if (loading) {
        return <p>Loading ....</p>; 
    }
      return (
        <div className="flex flex-col text-gray-800">
        <main className="flex-1 flex flex-col lg:flex-col">
        
          <div className="flex-1 flex flex-col items-center justify-center p-4 md:p-6">
         
            <div className="border-2 border-dashed border-gray-300 bg-white shadow-md w-full max-w-lg h-auto flex flex-col items-center justify-center rounded-lg p-4">
              <h3 className="text-lg font-bold mb-4">Trained File</h3>
              <input
                type="file"
                className="border w-full px-4 py-2 rounded-lg mb-4"
                placeholder="Choose File"
                onChange={handleFileChange}
              />
              <button
              onClick={() =>
                trainingfile( file, id)
               }
                className="bg-yellow-300 px-6 py-2 rounded-md hover:bg-yellow-400"
              > {loadingtrained ? (<svg
                className="animate-spin h-5 w-5 text-slate-950"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>) : ("Training File")} 
                
              </button>
            </div>
          </div>

          <div className="flex-1 flex flex-col items-center justify-center p-4 md:p-6">
          
            <div className="border-2 border-dashed border-gray-300 bg-white shadow-md w-full max-w-lg h-auto flex flex-col items-center justify-center rounded-lg p-4">
              <h3 className="text-lg font-bold mb-4">Edit Chatbot</h3>
              <input
                type="text"
                className="border w-full px-4 py-2 rounded-lg mb-4"
                placeholder="Enter Bot Name"
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
               <input
                type="text"
                className="border w-full px-4 py-2 rounded-lg mb-4"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
             
              <button
              onClick={() =>
               editBot(id, botName, description)
              }
                className="bg-yellow-300 px-6 py-2 rounded-md hover:bg-yellow-400"
              >
                Edit Bot
              </button>
            </div>
  
           
            
          </div>
        </main>
      </div>
    );
}

export default TrainedBot;




