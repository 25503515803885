
function UserPlan({activeplans}) {
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };
  return (
    <div class="container mx-auto mb-8">
        <h1 class="text-2xl font-bold mb-6">My Package</h1>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div class="bg-yellow-500 p-6 rounded-lg shadow-lg text-white flex flex-col justify-between">
          <div class="flex items-center space-x-4">
              <div class="text-lg  font-semibold">Package Plan</div>
          </div>
          <div class="text-3xl font-bold mt-2">{activeplans.duration ? activeplans.duration  : "No Active Plan" }</div>
      </div>

     
      <div class="bg-yellow-500 p-6 rounded-lg shadow-lg text-white flex flex-col justify-between">
          <div class="flex items-center space-x-4">
          
              <div class="text-lg font-semibold">Active User</div>
          </div>
          <div class="text-3xl font-bold mt-2">12k month</div>
      </div>

      <div class="bg-yellow-500 p-6 rounded-lg shadow-lg text-white flex flex-col justify-between">
        <div class="flex items-center space-x-4">
           
            <div class="text-lg font-semibold">Plan Name</div>
        </div>
        <div class="text-3xl font-bold mt-2">{activeplans ?  activeplans.plans.name : "No Active Plan" }</div>
    </div>
    <div class="bg-yellow-500 p-6 rounded-lg shadow-lg text-white flex flex-col justify-between">
        <div class="flex items-center space-x-4">
           
            <div class="text-lg font-semibold">Active until :</div>
        </div>
        <div class="text-3xl font-bold">{formatDate(activeplans.end_date)}</div>
        
    </div>
    </div>
    </div>
  );
}

export default UserPlan;
