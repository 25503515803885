import React, { useState } from 'react';
import axios from "axios";
import {   toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit =  async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://milio-backend-production.up.railway.app/auth/login", formData);
      const { token, user } = response.data;
      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      toast.success("Login Sukses", {
        position: "top-right",
        autoClose: 3000,
      });
      navigate("/dashboard");
     
    } catch (error) {
      if (error.response) {
        toast.error("Something went wrong", {
          position:  "top-center",
          autoClose: 3000,
        });
        setError(error.response.data.message);
      } else {
        toast.error("Something went wrong", {
          position:  "top-center",
          autoClose: 3000,
        });
        setError("An error occurred. Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="border border-yellow-400 rounded-lg flex flex-row w-2/3">
        <div
          id="login-section"
          className="w-full md:w-1/2 p-5 rounded-tl-lg rounded-bl-lg"
        >
          <div id="login-form">
            <p className="text-3xl font-semibold mb-4">Sign In</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="mb-1">
                  <label htmlFor="email" className="font-bold">
                    Email
                  </label>
                </div>
                <input
                  id="email"
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  className="p-3 w-full outline outline-1 focus:outline-2 active:outline-2 outline-yellow-400 rounded-full"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-5">
                <div className="mb-1">
                  <label htmlFor="password" className="font-bold">
                    Password
                  </label>
                </div>
                <input
                  id="password"
                  type="password"
                  autoComplete="off"
                  placeholder="Password"
                  className="p-3 w-full outline outline-1 focus:outline-2 active:outline-2 outline-yellow-400 rounded-full"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-yellow-300 w-full py-2 text-md font-semibold text-slate-950 rounded-full flex items-center justify-center"
              >
                {loading ? (<svg
            className="animate-spin h-5 w-5 text-slate-950"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>) : ("Sign In")} 
              </button>
            </form>
          </div>
        </div>

        {/* Registration Section */}
        <div
          id="regis-section"
          className="hidden md:flex md:w-1/2 bg-yellow-300 items-center p-5 rounded-tr-lg rounded-br-lg"
        >
          <div
            id="regis-card"
            className="text-center text-slate-950 flex w-full items-center justify-center"
          >
            <div className="leading-loose">
              <p className="text-3xl font-bold">Welcome to login</p>
              <p className="text-md font-semibold">Don't have an account?</p>
              <Link
                to="/register"
                className="show-regis-form outline outline-2 px-3 py-1 text-md font-semibold rounded-full outline-slate-950"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
