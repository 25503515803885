import React, { useState, useEffect } from "react";

function InboxChat() {
  const [activeChat, setActiveChat] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false); // State untuk loading
  const [error, setError] = useState(null); // State untuk error
  const [newMessage, setNewMessage] = useState("");
  const [handoverActive, setHandoverActive] = useState(false);
  const token = localStorage.getItem("authToken");

  const fetchChatData = async () => {
    setLoading(true); // Mulai loading
    setError(null); // Reset error
    try {
      const response = await fetch(
        `https://milio-backend-production.up.railway.app/chat-history/history/4cf938c7-a87f-4550-a004-f218df996c17`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.data && data.data.length > 0) {
          setChatHistory(data.data); // Simpan data jika ada
        } else {
          setError("No chat history found."); // Data kosong
        }
      } else {
        setError("Failed to fetch chat history."); // Gagal fetch
      }
    } catch (error) {
      setError("Error fetching chat history."); // Error saat fetch
    } finally {
      setLoading(false); // Selesai loading
    }
  };

  const handoverChat = async () => {
    const payload = {
      botId: "4cf938c7-a87f-4550-a004-f218df996c17",
      userPhoneNumber: activeChat.user_phone_number,
    };
    try {
      const response = await fetch(
        `https://milio-backend-production.up.railway.app/api/twilio/handover-chat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      await response.json();
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim() || !activeChat || !handoverActive) return;

    const payload = {
      to: activeChat.user_phone_number,
      body: newMessage,
      botId: "4cf938c7-a87f-4550-a004-f218df996c17",
      userPhoneNumber: activeChat.user_phone_number,
    };

    try {
      const response = await fetch(
        `https://milio-backend-production.up.railway.app/api/twilio/send-message`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          id: Date.now(),
          user_phone_number: activeChat.user_phone_number,
          message: newMessage,
          sender: "user",
          status: "manual",
        },
      ]);
      setNewMessage("");
      fetchChatData();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    fetchChatData();
  }, [token]);

  // Filter messages based on filter state
  const filteredMessages = chatHistory.filter((message) => {
    if (activeChat?.user_phone_number !== message.user_phone_number) {
      return false; // Only show messages for the selected user
    }
    if (filter === "all") return true;
    if (filter === "bot") return message.status === "bot";
    if (filter === "manual") return message.status === "manual";
    return false;
  }).reverse();

  // Get unique conversations from chat history
  const conversations = Array.from(
    new Map(chatHistory.map((item) => [item.user_phone_number, item])).values()
  );

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Conversations List */}
      <div className="w-1/4 bg-white shadow-lg overflow-y-auto">
        <div className="p-4 border-b">
          <h2 className="text-lg font-bold text-gray-800">Inbox</h2>
        </div>
        {/* Tampilkan loading, error, atau data */}
        {loading ? (
          <p className="p-4 text-gray-500">Loading chat history...</p>
        ) : error ? (
          <p className="p-4 text-red-500">{error}</p>
        ) : conversations.length > 0 ? (
          conversations.map((conversation) => (
            <div
              key={conversation.user_phone_number}
              className={`p-4 cursor-pointer hover:bg-blue-100 ${
                activeChat?.user_phone_number === conversation.user_phone_number
                  ? "bg-blue-50"
                  : ""
              }`}
              onClick={() => {
                setActiveChat(conversation);
                setHandoverActive(false);
              }}
            >
              <h3 className="text-md font-bold text-gray-800">
                {conversation.user_phone_number}
              </h3>
              <p className="text-sm text-gray-600">{conversation.message}</p>
            </div>
          ))
        ) : (
          <p className="p-4 text-gray-500">No conversations available.</p>
        )}
      </div>

      {/* Chat Section */}
      <div className="flex-1 flex flex-col">
        {/* Chat Header */}
        <div className="bg-yellow-400 text-white p-4 flex justify-between items-center">
          <h2 className="text-lg font-bold">
            {activeChat ? activeChat.user_phone_number : "Select a conversation"}
          </h2>
          {activeChat && (
            <div className="space-x-2">
              <button
                onClick={() => setFilter("all")}
                className={`px-4 py-2 rounded ${
                  filter === "all" ? "bg-white text-yellow-400" : "bg-yellow-400 text-white"
                }`}
              >
                All
              </button>
              <button
                onClick={() => setFilter("manual")}
                className={`px-4 py-2 rounded ${
                  filter === "manual" ? "bg-white text-yellow-400" : "bg-yellow-400 text-white"
                }`}
              >
                Manual
              </button>
              <button
                onClick={() => setFilter("bot")}
                className={`px-4 py-2 rounded ${
                  filter === "bot" ? "bg-white text-yellow-400" : "bg-yellow-400 text-white"
                }`}
              >
                Bot
              </button>
            </div>
          )}
        </div>

        {/* Chat Messages */}
        <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
          {activeChat ? (
            filteredMessages.map((message) => (
              <div
                key={message.id}
                className={`flex mb-4 ${
                  message.sender === "bot" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-xs px-4 py-2 rounded-lg ${
                    message.sender === "bot"
                      ? "bg-yellow-400 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  <p className="text-sm">{message.message}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center mt-4">No conversation selected.</p>
          )}
        </div>

        {/* Message Input */}
        {activeChat && (
          <div className="p-4 bg-white border-t">
            <div className="mb-2">
              <button
                onClick={() => {
                  setHandoverActive((prev) => !prev)
                  handoverChat();
                } }
                className={`px-4 py-2 rounded-lg ${
                  handoverActive ? "bg-red-500 text-white" : "bg-green-500 text-white"
                }`}
              >
                {handoverActive ? "Handover Active" : "Activate Handover"}
              </button>
            </div>
            <div className="flex">
              <input
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                disabled={!handoverActive} 
              />
              <button
                onClick={sendMessage}
                className="ml-4 bg-yellow-400 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                disabled={!handoverActive} 
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InboxChat;
