import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

const Contacts = () => {
  const [contacts, setContacts] = useState([]); 
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const token = localStorage.getItem("authToken");
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
};

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.name.toLowerCase().includes(search.toLowerCase()) ||
      contact.phone.includes(search)
  );
  const fetchContact = async () => {
    try {
      setLoading(true);
        const response = await fetch("https://milio-backend-production.up.railway.app/contacts",  {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
          
        });
        const data = await response.json();
        setContacts(data.data);
       
    } catch (error) {
        console.error("Error creating file:", error);
    }
    setLoading(false);
};

  const paginatedContacts = filteredContacts.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  useEffect(() => {
    fetchContact();
  }, []);

  return (
    <div className="p-4 sm:p-6  min-h-screen">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">Contacts</h1>
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4 space-y-2 sm:space-y-0">
        {/* Search and Filter Section */}
        <div className="flex items-center w-full sm:w-auto space-x-2">
          <button
            onClick={() => alert("Filter clicked")}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-300 rounded text-sm sm:text-base"
          >
            Filter
          </button>
          <input
            type="text"
            placeholder="Search by Name or Phone"
            value={search}
            onChange={handleSearch}
            className="flex-1 sm:w-80 border px-4 py-2 rounded text-sm sm:text-base"
          />
        </div>
        {/* Action Buttons */}
        <div className="flex items-center space-x-2 w-full sm:w-auto">
          <button
            onClick={() => alert("Export clicked")}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-300 rounded text-sm sm:text-base"
          >
            Export
          </button>
          <button
            onClick={() => alert("Customize Columns clicked")}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-300 rounded text-sm sm:text-base"
          >
            Customize Columns
          </button>
        </div>
      </div>
      {loading && <ClipLoader size={50} color={"#f1c40f"} />}

      {/* Table Section */}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-yellow-400 text-sm sm:text-base">
          <thead className="bg-yellow-100">
            <tr>
              <th className="border border-yellow-400 px-2 py-2 text-left">Name</th>
              <th className="border border-yellow-400 px-2 py-2 text-left">Phone</th>
              <th className="border border-yellow-400 px-2 py-2 text-left">Bot Id</th>
              <th className="border border-yellow-400 px-2 py-2 text-left">User Id</th>
             
              <th className="border border-yellow-400 px-2 py-2 text-left">Chat Status</th>
              <th className="border border-yellow-400 px-2 py-2 text-left">Chat Created At</th>
             
            </tr>
          </thead>
          <tbody>
            {paginatedContacts.length > 0 ? (
              paginatedContacts.map((contact, index) => (
                <tr key={index} className="text-center">
                  <td className="border border-yellow-400 px-2 py-2">{contact.name}</td>
                  <td className="border border-yellow-400 px-2 py-2">{contact.phone}</td>
                  <td className="border border-yellow-400 px-2 py-2">{contact.bot_id}</td>
                  <td className="border border-yellow-400 px-2 py-2">{contact.user_id}</td>
                  <td className="border border-yellow-400 px-2 py-2">{contact.chat_status}</td>
                  <td className="border border-yellow-400 px-2 py-2"> { formatDate(contact.created_at)}</td>
                
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="9"
                  className="border border-yellow-400 px-2 py-2 text-center text-yellow-500"
                >
                  No Contacts Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
        <div className="text-sm sm:text-base">
          Page {currentPage} of {Math.ceil(filteredContacts.length / rowsPerPage) || 1}
        </div>
        <div className="flex space-x-2 mt-2 sm:mt-0">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-300 rounded disabled:opacity-50 text-sm sm:text-base"
          >
            Previous
          </button>
          <button
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(filteredContacts.length / rowsPerPage))
              )
            }
            disabled={currentPage === Math.ceil(filteredContacts.length / rowsPerPage) || 1}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-300 rounded disabled:opacity-50 text-sm sm:text-base"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
