import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

const ConnectedPlatforms = () => {
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});

  const token = localStorage.getItem("authToken");

  // Fetch data dari API
  const fetchPlatforms = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://milio-backend-production.up.railway.app/platform",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPlatforms(data.data); // Simpan data dari API ke state
      } else {
        alert("Failed to fetch platforms");
      }
    } catch (error) {
      console.error("Error fetching platforms:", error);
      alert("Error fetching platforms");
    } finally {
      setLoading(false);
    }
  };
  const handleCreateNew = () => {
    alert("Create New Platform");
    // Implementasikan logika untuk membuat platform baru
  };

  useEffect(() => {
    fetchPlatforms(); // Fetch data saat komponen dimuat
  }, []);

  const handleSelectPlatform = (platform) => {
    setSelectedPlatform(platform);
    setEditData({
      bot_id: platform.bot_id,
      agent_id: platform.agent_id,
      platform_name: platform.platform_name,
    });
  };

  const handleCloseDetails = () => {
    setSelectedPlatform(null);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    if (selectedPlatform) {
      setLoading(true);
      try {
        const response = await fetch(
          `https://milio-backend-production.up.railway.app/platform/${selectedPlatform.id}/update`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editData),
          }
        );

        if (response.ok) {
          alert("Platform updated successfully!");
          fetchPlatforms(); // Refresh data setelah update
          handleCloseDetails();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message || "Failed to update platform"}`);
        }
      } catch (error) {
        console.error("Error updating platform:", error);
        alert("Error updating platform");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="p-6 min-h-screen">
      {/* Header */}
      <h1 className="text-3xl font-bold text-center mb-2">Connected Platforms</h1>
      <p className="text-center text-gray-600 mb-6">
        Ini adalah daftar Platform yang telah Anda sambungkan ke milio AI. <br />
        Jangan ragu untuk membuat perubahan dan menghubungkan sebanyak mungkin platform kapan pun Anda mau!
      </p>
 
      {loading ? (
        <div className="text-center"><ClipLoader size={50} color={"#f1c40f"} /></div>
      ) : (
        <div className="flex flex-wrap justify-center gap-4">
          {/* Tampilkan Platform Cards */}
          {platforms.map((platform) => (
            <div
              key={platform.id}
              onClick={() => handleSelectPlatform(platform)}
              className="w-64 h-32 bg-white shadow-md rounded-lg flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:shadow-lg"
            >
              <div className="text-4xl">📱</div> {/* Ikon placeholder */}
              <h3 className="text-lg font-bold mt-2">{platform.platform_name}</h3>
              <p className="text-sm text-gray-500">{platform.platform_type}</p>
            </div>
            
          ))}
           <div
            onClick={handleCreateNew}
            className="w-64 h-32 bg-yellow-500 shadow-md rounded-lg flex flex-col items-center justify-center cursor-pointer hover:bg-blue-600"
          >
            <div className="text-4xl text-white">+</div>
            <h3 className="text-lg font-bold text-white mt-2">Create New</h3>
          </div>
        </div>
      )}

      {/* Detail Modal */}
      {selectedPlatform && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <h2 className="text-xl font-bold mb-4">
              {isEditing
                ? `Edit ${selectedPlatform.platform_name}`
                : `${selectedPlatform.platform_name} Details`}
            </h2>
            {isEditing ? (
              <div className="space-y-4">
                <input
                  type="text"
                  name="bot_id"
                  value={editData.bot_id}
                  onChange={handleInputChange}
                  placeholder="Bot ID"
                  className="w-full border px-4 py-2 rounded"
                />
                <input
                  type="text"
                  name="agent_id"
                  value={editData.agent_id}
                  onChange={handleInputChange}
                  placeholder="Agent ID"
                  className="w-full border px-4 py-2 rounded"
                />
                <input
                  type="text"
                  name="platform_name"
                  value={editData.platform_name}
                  onChange={handleInputChange}
                  placeholder="Platform Name"
                  className="w-full border px-4 py-2 rounded"
                />
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleCloseDetails}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveEdit}
                    className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  <strong>Platform Type:</strong> {selectedPlatform.platform_type}
                </p>
                <p>
                  <strong>Phone Number:</strong> {selectedPlatform.phone_number_id}
                </p>
                <p>
                  <strong>Status:</strong> {selectedPlatform.status}
                </p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {new Date(selectedPlatform.created_at).toLocaleString()}
                </p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleEdit}
                    className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleCloseDetails}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectedPlatforms;
